<template>
  <StyledModal :visible="visible" :title="`${isAdd ? '新建' : '编辑'}banner`" @close="close" @submit="submit" :submitLoading="submitLoading" width="800px">
    <el-form class="form_box" ref="formRef" :model="formModel.form" label-width="120px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="标题" prop="bannerTitle" :rules="[{ required: true, message: '请输入标题' }]">
            <el-input v-model="formModel.form.bannerTitle" placeholder="请输入"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="轮播类型" prop="bannerType" :rules="[{ required: true, message: '请选择轮播类型' }]">
            <FetchSelect
              v-model="formModel.form.bannerType"
              placeholder="轮播类型"
              api="/web/sys/code/getSysCodeByParam"
              :postData="{ codeParam: 'DOCTOR_BANNER_TYPE' }"
              textKey="codeValue"
              valueKey="codeKey"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="跳转类型" prop="purchaseType" :rules="[{ required: true, message: '请选择跳转类型' }]">
            <FetchSelect
              v-model="formModel.form.purchaseType"
              placeholder="跳转类型"
              api="/web/sys/code/getSysCodeByParam"
              :postData="{ codeParam: 'DOCTOR_BANNER_PURCHASE_TYPE' }"
              textKey="codeValue"
              valueKey="codeKey"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="是否销售" prop="saleStatus" :rules="[{ required: true, message: '请选择是否销售' }]">
            <FetchSelect
              v-model="formModel.form.saleStatus"
              placeholder="是否销售"
              api="/web/sys/code/getSysCodeByParam"
              :postData="{ codeParam: 'DOCTOR_BANNER_SALE_STATUS' }"
              textKey="codeValue"
              valueKey="codeKey"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="在售按钮文案" prop="forSaleText" :rules="[{ required: true, message: '请输入在售按钮文案' }]">
            <el-input v-model="formModel.form.forSaleText" placeholder="请输入"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="备货按钮文案" prop="inStockText" :rules="[{ required: true, message: '请输入备货按钮文案' }]">
            <el-input v-model="formModel.form.inStockText" placeholder="请输入"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="所属医生" prop="doctorCode" :rules="[{ required: true, message: '请选择所属医生' }]">
            <FetchSelect
              v-model="formModel.form.doctorCode"
              api="/web/system/doctorncp/getDoctorList"
              valueKey="doctorCode"
              textKey="doctorName"
              @change="formModel.form.goodsCode = ''"
            />
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item label="关联商品" prop="goodsCode" :rules="[{ required: true, message: '请选择关联商品' }]">
            <FetchSelect
              v-model="formModel.form.goodsCode"
              :ready="Boolean(formModel.form.doctorCode)"
              api="/web/system/doctorbanner/getGoodsList"
              :postData="{
                doctorCode: formModel.form.doctorCode,
              }"
              valueKey="goodsCode"
              textKey="showText"
              :dealRes="dealGoodsSelectRes"
            />
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="封面图片" prop="bannerTitleImage" :rules="[{ required: true, message: '请上传图片' }]">
            <div>
              <div><ImgUpload v-model="formModel.form.bannerTitleImage" :limit="1" /></div>
              <div>建议尺寸 1080*398</div>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="排序"
            prop="bannerSeq"
            :rules="[
              { required: true, message: '请输入排序' },
              { type: 'number', min: 0, max: 99999, message: '请输入0-99999之间的数字' },
            ]"
          >
            <el-input v-model.number="formModel.form.bannerSeq" placeholder="0-99999之间"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="详情图片" prop="detailImg" :rules="[{ required: true, message: '请上传图片' }]">
        <DetailEditor v-model="formModel.form.detailImg" :types="['image']" />
      </el-form-item>
    </el-form>
  </StyledModal>
</template>

<script>
import { reactive, ref, computed } from 'vue'
import { cloneDeep } from 'lodash'
import StyledModal from '@/components/modal/StyledModal/index.vue'
import ImgUpload from '@/components/ImgUpload'
import FetchSelect from '@/components/FetchSelect/index.vue'
import { validateFormRef } from '@/common/utils_element'
import request from '@/utils/request'
import DetailEditor from '@/components/DetailEditor/index.vue'

const defaultForm = {
  bannerTitle: '',
  bannerType: '',
  doctorCode: '',
  saleStatus: '',
  goodsCode: '',
  bannerTitleImage: '',
  bannerSeq: '',
  purchaseType: '',
  detailImg: '',
  forSaleText: '',
  inStockText: '',
}

// 拼一下 商城的skuCode
function dealGoodsSelectRes(data) {
  return (data ?? []).map((item) => {
    return {
      ...item,
      showText: `${item.goodsName} - ${item.chnlGoodsId}`,
    }
  })
}

export default {
  emits: ['successAdd', 'successEdit'],
  setup(_, ctx) {
    const visible = ref(false)
    const submitLoading = ref(false)

    const formRef = ref(null)
    const formModel = reactive({
      form: cloneDeep(defaultForm),
    })

    const record = reactive({ v: null })
    const isAdd = computed(() => (record.v ? false : true))

    function open(recordParam) {
      visible.value = true
      if (recordParam) {
        record.v = cloneDeep(recordParam)
        renderForm(cloneDeep(recordParam))
      }
    }

    function close() {
      visible.value = false
      record.v = null
      formModel.form = cloneDeep(defaultForm)
    }

    // form回显
    function renderForm(data) {
      formModel.form = {
        ...data,
      }
    }

    // 提交
    async function submit() {
      await validateFormRef(formRef)
      submitLoading.value = true
      request({
        url: isAdd.value ? '/web/system/doctorbanner/createDoctorBanner' : '/web/system/doctorbanner/updateDoctorBanner',
        data: {
          ...formModel.form,
          ...(isAdd.value ? {} : { bannerCode: record.v.bannerCode }),
        },
      })
        .finally(() => {
          submitLoading.value = false
        })
        .then(() => {
          ctx.emit(isAdd.value ? 'successAdd' : 'successEdit')
          close()
        })
    }

    return {
      visible,
      isAdd,
      submitLoading,
      open,
      close,
      submit,
      //
      formRef,
      formModel,
      dealGoodsSelectRes,
    }
  },
  components: { StyledModal, FetchSelect, ImgUpload, DetailEditor },
}
</script>

<style lang="less" scoped>
.form_box {
  width: 90%;
}
</style>
