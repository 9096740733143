<template>
  <SearchForm ref="searchFormRef" :formModel="searchController.formModel" @search="tableController.search" :searchLoading="tableController.loading">
    <template #form>
      <el-form-item prop="bannerTitle">
        <el-input placeholder="标题" v-model="searchController.formModel.bannerTitle" clearable></el-input>
      </el-form-item>
      <el-form-item prop="doctorCode">
        <FetchSelect placeholder="所属医生" v-model="searchController.formModel.doctorCode" api="/web/system/doctorncp/getDoctorList" valueKey="doctorCode" textKey="doctorName" />
      </el-form-item>
      <el-form-item prop="saleStatus">
        <FetchSelect
          v-model="searchController.formModel.saleStatus"
          placeholder="是否销售"
          api="/web/sys/code/getSysCodeByParam"
          :postData="{ codeParam: 'DOCTOR_BANNER_SALE_STATUS' }"
          textKey="codeValue"
          valueKey="codeKey"
        />
      </el-form-item>
      <el-form-item prop="disabled">
        <el-select placeholder="状态" v-model="searchController.formModel.disabled" clearable>
          <el-option label="有效" :value="0"></el-option>
          <el-option label="无效" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="purchaseType">
        <FetchSelect
          v-model="searchController.formModel.purchaseType"
          placeholder="跳转类型"
          api="/web/sys/code/getSysCodeByParam"
          :postData="{ codeParam: 'DOCTOR_BANNER_PURCHASE_TYPE' }"
          textKey="codeValue"
          valueKey="codeKey"
        />
      </el-form-item>
      <el-form-item prop="bannerType">
        <FetchSelect
          v-model="searchController.formModel.bannerType"
          placeholder="轮播类型"
          api="/web/sys/code/getSysCodeByParam"
          :postData="{ codeParam: 'DOCTOR_BANNER_TYPE' }"
          textKey="codeValue"
          valueKey="codeKey"
        />
      </el-form-item>
    </template>
    <template #controll>
      <el-button type="primary" @click="clickAdd">新建</el-button>
    </template>
  </SearchForm>
  <ProTable
    :columns="tableController.columns"
    :tableData="tableController.tableData"
    :loading="tableController.loading"
    :total="tableController.total"
    :pageState="tableController.pageState"
    @pageChange="tableController.onPageChange"
  />

  <!-- 模态 -->
  <AddModal ref="addModalRef" @successAdd="tableController.search" @successEdit="tableController.refresh" />
</template>

<script>
import { ref } from 'vue'
import { ElMessage } from 'element-plus'
import SearchForm from '@/components/SearchForm'
import ProTable from '@/components/ProTable'
import AddModal from './AddModal.vue'
import FetchSelect from '@/components/FetchSelect'
import CustomLink from '@/components/customStyle/CustomLink'
import useSearchFormCtrller from '@/hooks/useSearchFormCtrller'
import useTableController from '@/hooks/useTableController'
import { actionConfirm } from '@/utils/confirm'
import request from '@/utils/request'

export default {
  setup() {
    const addModalRef = ref(null)
    // searchForm
    const searchController = useSearchFormCtrller({
      form: {
        bannerTitle: '',
        doctorCode: '',
        saleStatus: '',
        disabled: '',
        purchaseType: '',
        bannerType: '',
      },
    })
    // table
    const columns = [
      {
        label: '图片',
        prop: 'bannerTitleImage',
        width: 130,
        render: (v, record) => {
          const styleStr = `width:auto; height:${48}px;`
          return <el-image src={v} fit="cover" style={styleStr}></el-image>
        },
      },
      { label: '标题', prop: 'bannerTitle' },
      { label: '所属医生', prop: 'doctorName', width: 120 },
      { label: '轮播类型', prop: 'bannerTypeName', width: 80 },
      { label: '是否销售', prop: 'saleStatusName', width: 80 },
      { label: '跳转类型', prop: 'purchaseTypeName', width: 90 },
      {
        label: '关联商品',
        prop: 'goodsName',
        render: (v, record) => {
          return (
            <div>
              {record.goodsName}（{record.chnlGoodsId}）
            </div>
          )
        },
      },
      { label: '商品编码', prop: 'goodsCode', width: 140 },
      { label: '状态', prop: 'disabled', width: 80, render: (v) => (v == 0 ? '有效' : '无效') },
      { label: '创建时间', prop: 'createDateStr' },
      { label: '操作人', prop: 'caozuoren', width: 140, render: (v, record) => record.updatePersonName || record.createPerson || '' },
      { label: '修改时间', prop: 'updateDateStr' },
      { label: '排序', prop: 'bannerSeq', width: 80 },
      {
        label: '操作',
        prop: 'actions',
        width: 100,
        fixed: 'right',
        render: (_, record) => {
          const statusText = record.disabled == '0' ? '失效' : '生效'
          return (
            <div>
              <CustomLink
                onClick={() => {
                  clickEdit(record)
                }}
              >
                编辑
              </CustomLink>
              <CustomLink
                onClick={() => {
                  handleToggleStatus(record)
                }}
              >
                {statusText}
              </CustomLink>
            </div>
          )
        },
      },
    ]
    const tableController = useTableController({
      columns,
      ajax: ({ page, pageSize }) => {
        const postData = {
          page,
          rows: pageSize,
          ...searchController.formModel,
        }
        return request({
          url: '/web/system/doctorbanner/getDoctorBannerPaging',
          data: postData,
        }).then((data) => ({ list: data.data ?? [], totalNum: data.rowTop ?? 0 }))
      },
    })

    function clickAdd() {
      addModalRef.value?.open()
    }

    function clickEdit(record) {
      addModalRef.value?.open(record)
    }

    function handleToggleStatus(record) {
      const statusText = record.disabled == '0' ? '失效' : '生效'
      actionConfirm(`确认${statusText}？`, () => {
        return request({
          url: '/web/system/doctorbanner/updateDoctorBannerDisabled',
          data: {
            bannerCode: record.bannerCode,
            disabled: record.disabled == '0' ? '1' : '0',
          },
        }).then(() => {
          tableController.refresh()
          ElMessage.success('操作成功')
        })
      })
    }

    return {
      searchController,
      tableController,
      //
      addModalRef,
      clickAdd,
    }
  },
  components: { SearchForm, ProTable, FetchSelect, AddModal },
}
</script>

<style></style>
